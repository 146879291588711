$quizado-primary: #2E36CB;
$quizado-secondary: #FFC500;
$quizado-accent1: #ffeee0;
//$quizado-accent2: #FFB6A8;
$quizado-accent2: #F8E7DB;

$quizado-black: #030417;
$quizado-white: #ffffff;

$quizado-font-family-base: 'Bariol Serif', 'Arial', sans-serif;
$quizado-font-family-base-headers: 'CHANEY', 'Arial', sans-serif;
$quizado-font-family-for-reading: 'Merriweather', serif;